import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const Table = styled.table`
  width: 920px;
  margin: 0px auto;
  border-collapse: collapse;

  tr:first-child {
    background: #f2faff;
    font-size: 18px;
  }

  td {
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background: none;
    font-size: 14px;

    a {
      color: #000;
    }
  }
`

export default () => {
  const publishDate = date => {
    if (!date) return 'STATIC'
    if (date === 'STATIC') return date
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return new Date(date).toLocaleDateString('en-US', options)
  }

  const {
    pages: { nodes },
  } = useStaticQuery(graphql`
    {
      pages: allSitePage(sort: { fields: [context___updatedAt], order: [DESC] }) {
        nodes {
          path
          context {
            createdAt
            updatedAt
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Table>
        <tbody>
          <tr>
            <td>URL</td>
            <td>Created at</td>
            <td>Updated at</td>
          </tr>

          {nodes.map((page, index) => (
            <tr key={index}>
              <td>
                <a href={page.path} target="_blank">
                  {page.path}
                </a>
              </td>
              <td>{publishDate(page.context?.createdAt)}</td>
              <td>{publishDate(page.context?.updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
